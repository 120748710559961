/* 首字母大写 */
export const capitalizeEveryWord = str => str.replace(/\b[a-z]/g, char => char.toUpperCase())

/**
 * 生成下拉数据
 * @param labelProp String
 * @param valueProp String
 * @returns Object 下拉数据
 */
export const generatedOptions = (labelProp = 'label', valueProp = 'value') => originSource => {
  return Object.keys(originSource).map(key => ({
    [labelProp]: key,
    [valueProp]: originSource[key]
  }))
}

// 去除前后空格
export const trim = str => {
  if (Object.prototype.toString.call(str) === '[object String]') {
    return str.trim()
  }
  return str
}

// 是否为对象
export const isObject = value => {
  const type = typeof value
  return value !== null && (type === 'object' || type === 'function')
}

// 是否为数组
export const isArray = value => {
  const _isArray =
    Array.isArray || (_arg => Object.prototype.toString.call(_arg) === '[object Array]')
  return _isArray(value)
}

// 是否为空
export const isEmpty = value => {
  if (value === null || value === undefined) return true
  if (isObject(value)) return Object.keys(value).length === 0
  if (isArray(value)) return value.length === 0

  return false
}

// 是否为字符串
export const isString = value => {
  return Object.prototype.toString.call(value) === '[object String]'
}

// 是否为数字
export const isNumber = value => {
  return typeof value === 'number' && !isNaN(value)
}

// 下载指定文件
export const downloadFilesUrl = url => {
  let iframe = document.createElement('iframe')
  iframe.style.display = 'none'
  iframe.src = url
  iframe.onload = function () {
    document.body.removeChild(iframe)
  }
  document.body.appendChild(iframe)
}

// 字符串省略号处理
export const textFlow = (value, len) => {
  len = len || 8
  value = value || ''
  let str = value + ''
  if (str.length > len) str = str.substr(0, len) + '...'
  return str
}

export function getUid() {
  return (
    random() +
    random() +
    '-' +
    random() +
    '-' +
    random() +
    '-' +
    random() +
    '-' +
    random() +
    random() +
    random()
  )
}

function random() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}

export const getRandom = () => {
  return Date.now().toString() + Math.floor(Math.random(1) * 100).toString()
}

export function mergeObjs(def, obj) {
  if (!obj) {
    return def
  } else if (!def) {
    return obj
  }
  for (let i in obj) {
    // 是否是对象
    if (obj[i] != null && obj[i].constructor === Object) {
      def[i] = mergeObjs(def[i], obj[i])
    }
    // 如果是数组, 简单类型可以直接拼接.  对象类型需要递归，继续遍历.
    else if (obj[i] != null && obj[i].constructor === Array && obj[i].length > 0) {
      def[i] = mergeObjs(def[i], obj[i])
    } else {
      def[i] = obj[i]
    }
  }
  return def
}

export function deepClone(initialObj) {
  if (typeof initialObj !== 'object' || initialObj === null) {
    return initialObj
  }
  // 数组
  if (Array.isArray(initialObj)) {
    return initialObj.map(val => deepClone(val))
  }
  let obj = {}
  for (let key in initialObj) {
    if (typeof initialObj[key] === 'object') {
      // 普通对象
      obj[key] = deepClone(initialObj[key])
    } else if (typeof initialObj[key] === 'function') {
      // 方法
      obj[key] = initialObj[key].bind(obj)
    } else {
      // 基本数据类型
      obj[key] = initialObj[key]
    }
  }
  return obj
}

/**
 * @desc 函数防抖
 * @param func 目标函数
 * @param wait 延迟执行毫秒数
 * @param immediate true - 立即执行， false - 延迟执行
 */
export function debounce(func, wait, immediate) {
  let timer
  return function () {
    let context = this
    let args = arguments

    if (timer) clearTimeout(timer)
    if (immediate) {
      let callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, wait)
      if (callNow) func.apply(context, args)
    } else {
      timer = setTimeout(() => {
        func.apply(context, args)
      }, wait)
    }
  }
}

/**
 * @description			判断是否为json字符串
 * @author				hauner
 * @param json   字符串
 * @data				2022-04-14
 */
export function isJson(json) {
  try {
    JSON.parse(json)
    return ture
  } catch (e) {
    return false
  }
}

/**
 * @description			判断是否为json字符串并转成Json对象
 * @author				hauner
 * @param json   字符串
 * @data				2022-04-14
 */
export function parseJson(json) {
  try {
    let o = JSON.parse(json)
    return o
  } catch (e) {
    return null
  }
}

/**
 * @description			按格式格式化时间
 * @author				hauner
 * @param fmt   格式字符串，比如：‘yyyy-MM-dd’
 * @param date  要格式化的时间变量，可以是任何能够转换成时间的变量
 * @data				2022-04-07
 */
export function formatTime(fmt = 'yyyy-MM-dd', date = new Date()) {
  let ret
  date = new Date(date)
  if (!(date instanceof Date)) throw Error(`${date.toString}不是一个正确的时间`)
  const opt = {
    'y+': date.getFullYear().toString(), // 年
    'M+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'h+': date.getHours().toString(), // 时
    'm+': date.getMinutes().toString(), // 分
    's+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
    }
  }
  return fmt
}

/**
 * @description			判断小数位数
 * @author				hauner
 * @data				2022-04-28
 */

export function decpos(num) {
  if (isNaN(num)) throw Error(`${num}不是数字`)
  let float = num.toString().split('.')
  if (!float[1]) return 0
  return float[1].length
}

/**
 * @description			补零函数
 * @author				hauner
 * @param num   要补零的正整数数字
 * @param count 补零之后的为数
 * @data				2022-03-29
 */
export function fillZero(num, count = 2) {
  if (isNaN(num)) num = ''
  if (isNaN(count)) count = 2
  if (count < 0) count = -count
  if (`${num}`.length > count) return num
  return `${new Array(count).join('0')}${num}`.slice(-count)
}

export function formatFileSize(fileSize) {
  let temp = null
  if (fileSize < 1024) {
    return fileSize + 'B'
  } else if (fileSize < 1024 * 1024) {
    temp = fileSize / 1024
    temp = temp.toFixed(2)
    return temp + 'KB'
  } else if (fileSize < 1024 * 1024 * 1024) {
    temp = fileSize / (1024 * 1024)
    temp = temp.toFixed(2)
    return temp + 'MB'
  } else {
    temp = fileSize / (1024 * 1024 * 1024)
    temp = temp.toFixed(2)
    return temp + 'GB'
  }
}

export function getBaseUrl() {
  let baseUrl =
    window.location.href.indexOf('#') > -1
      ? window.location.href.split('#')[0]
      : window.location.href
  return baseUrl
}

export function getDictLabel(arr, value, key = 'dictCode', rKey = 'name', ret) {
  if (isEmpty(arr)) return ''
  const option = arr.find(item => item[key] == value)
  return option ? option[rKey] : ret ? ret : ''
}

export function toThousands(num) {
  return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
}

export function hasAuth($store, key) {
  const btns = $store.state.app.btns
  const userInfo = $store.state.app.userInfo
  if (userInfo.userName === 'admin') {
    return true
  } else {
    return btns.includes(key)
  }
}

export default {
  capitalizeEveryWord,
  generatedOptions,
  trim,
  isObject,
  isArray,
  isEmpty,
  isString,
  downloadFilesUrl,
  textFlow,
  getUid,
  mergeObjs,
  deepClone,
  debounce,
  formatFileSize,
  getBaseUrl,
  getDictLabel,
  toThousands,
  hasAuth
}
