import axios from '@/common/js/request'
export function checkUserSave(data){ // 添加档案
    const checkUserSave = axios({
        url: '/gsCheckEquipment/save',
        method: 'post',
        data
    })
    return checkUserSave
}
export function checkUserUpdate(data){ // 编辑档案
    const checkUserUpdate = axios({
        url: '/gsCheckEquipment/update',
        method: 'post',
        data
    })
    return checkUserUpdate
}
export function checkUserSelect(params){ // 档案管理查询
    const checkUserSelect = axios({
        url: '/gsCheckEquipment/selectCheckEquipment',
        method: 'get',
        params
    })
    return checkUserSelect
}
export function checkUserDelete(params){ // 删除档案
    const checkUserDelete = axios({
        url: '/gsCheckEquipment/delete',
        method: 'get',
        params
    })
    return checkUserDelete
}
export function selectByExcel(params){ // 档案管理导出
    const selectByExcel = axios({
        url: '/gsCheckUser/selectByExcel',
        method: 'get',
        params
    })
    return selectByExcel
}
export function downloadUserExcel(params){ // 下载档案excel模板
    const downloadUserExcel = axios({
        url: '/gsCheckUser/downloadUserExcel',
        method: 'get',
        params
    })
    return downloadUserExcel
}
export function selectAddrPullDown(params){ // 行政区域下拉
    const selectAddrPullDown = axios({
        url: '/gsCheckAddr/selectAddrPullDown',
        method: 'get',
        params
    })
    return selectAddrPullDown
}
export function selectDictListByPid(params){ // 字典
    const selectDictListByPid = axios({
        url: '/dict/selectDictListByPid',
        method: 'get',
        params
    })
    return selectDictListByPid
}
///gsCheckUser/selectUserPullDown
export function selectUserPullDown(params){
    const selectUserPullDown = axios({
        url: '/gsCheckUser/selectUserPullDown',
        method: 'get',
        params
    })
    return selectUserPullDown
}
///gsCheckEquipment/updateStatus
export function updateStatus(params){
    const updateStatus = axios({
        url: '/gsCheckEquipment/updateStatus',
        method: 'get',
        params
    })
    return updateStatus
}